import React, { useState } from "react"
import { Row, Col, Container, Form, FormGroup, Input, Button } from "reactstrap"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import addToMailchimp from 'gatsby-plugin-mailchimp'

const useInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: event => {
        setValue(event.target.value);
      }
    }
  }
}

const Newsletter = () => {  
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput('');
    
  const handleSubmit = (e) => {
    e.preventDefault();
    addToMailchimp(email)
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        console.log(data)
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
        console.error("An error occured")
      })
    alert(`Thanks for signing up to our newsletter with your email address ${email}.`);
    resetEmail();
  }

  return (
    <div className="bg-ci-darker-blue text-light">
      <Container>
        <Row className="align-items-center justify-content-left">
          <Col xl="6" className="py-5">
            <p className="lead">
              Subscribe to our newsletter and stay updated on the latest developments.
            </p>
            <Form inline onSubmit={handleSubmit}>
              <FormGroup>
                <Input bsSize="lg" type="email" name="email" id="signupEmail" placeholder="Your email address ..." {...bindEmail} className="mr-1" />
                <Button size="lg" color="light" outline>Sign up</Button>
              </FormGroup>
            </Form>
            {/*<small>
              By subscribing to our newsletter you agree that we are allowed to save your email address and that you receive emails from us.
            </small>*/}
          </Col>
          <Col className="py-5">
            <p className="lead">Are you interested in our past issues?</p>
            
            <OutboundLink 
              href="https://us20.campaign-archive.com/home/?u=bb171d2cf50571cfa9f448e4f&id=6b77e56044" 
              className="btn btn-lg btn-primary"
            >
              Browse past issues
            </OutboundLink>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Newsletter